import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../../components/ChineseLayout"
import FarmerBg from "../../images/static-only/visit-one-of-our-farms.jpg"
import BabyBg from "../../images/static-only/babys-development/babys-dev-menu.jpg"
import ScienceBg from "../../images/static-only/the-science-menu.jpg"
// import Inactivity from "../../components/Inactivity"

var farmerBg = {
  backgroundImage: `url(${ FarmerBg })`
}

var babyBg = {
  backgroundImage: `url(${ BabyBg })`
}

var scienceBg = {
  backgroundImage: `url(${ ScienceBg })`
}

/* Event Experience structure > Large menu items */

class EventExperience extends React.Component {
  componentDidMount() {
    // Inactivity()
    document.body.classList.remove('-nav-active')
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Meet MJN - 史基浦机场活动体验</title>
        </Helmet>
        <main role="main">

          <section className="top-level-menu-list">
            
            <div className="top-level-menu-list__item" style={farmerBg}>
              <span className="tl-menu-item__title">探访我们的牧场之一</span>
              <Link to="/zh/event-experience/grazing" className="cta -gold-gradient">阅读更多</Link>
            </div>

            <div className="top-level-menu-list__item -light-bg" style={babyBg}>
              <span className="tl-menu-item__title">探索宝宝成长轨迹</span>
              <Link to="/zh/event-experience/explore-babys-development" className="cta -gold-gradient">阅读更多</Link>
            </div>

            <div className="top-level-menu-list__item" style={scienceBg}>
              <span className="tl-menu-item__title">了解产品成分</span>
              <Link to="/zh/event-experience/learn-about-our-ingredients" className="cta -gold-gradient">阅读更多</Link>
            </div>

          </section>

        </main>
      </Layout>
    )
  }
}

export default EventExperience